<template>
    <caja>
      <h1>Bienvenido a la revolución del feedback. <br><br> Para información, escribenos a <a href="mailto:hola@enti.cl">hola@enti.cl</a></h1>
      <br><br>
      <h3><router-link to="/login" class="login">Acceso empresas</router-link></h3>
    </caja>
</template>

<script>
import Caja from './Caja.vue'
export default {
  components: { Caja },
}
</script>

<style scoped lang="scss">
a {
  color: #4CAF50;

  &:hover {
    color: orange;
  }

  &.login {
    border-bottom: 1px dashed;
  }
}
</style>